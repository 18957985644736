.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  background-color: #4e80f42d;
  padding: 20px;
  position: relative;
  width: fit-content;
}

.leftSectionWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-end;
  padding-right: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dotted #879ac375;
  border-right: 1px dotted #879ac375;
}

.rightSectionWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-end;
  padding-left: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dotted #879ac375;
}

.bottomLeftSectionWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-end;
  padding-right: 20px;
  padding-top: 20px;
  border-right: 1px dotted #879ac375;
}

.bottomRightSectionWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-end;
  padding-left: 20px;
  padding-top: 20px;
}

.individualTeethWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  gap: 20px;
  padding: 5px;
  z-index: 2;
}

.teethNumberSelected {
  color: #b9b9b9;

  &:hover {
    cursor: default;
  }
}

.headerContainer {
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  align-items: center;
}

.headerText {
  font-size: 22px;
  font-weight: 500;
  color: white;
}

.radioButtons {
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  align-items: center;
}

.rightText {
  position: absolute;
  bottom: 47.8%;
  left: 1%;
}

.leftText {
  position: absolute;
  bottom: 47.8%;
  right: 1%;
}
