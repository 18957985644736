@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.125rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* for modal in profile section  */

/* middle nav z index 12  */
/* pagination 12  */
/* middle tab index 13  */
/* modal z index 14  */

.ReactModal__Overlay {
  z-index: 14;
}
.custom-modal {
  position: absolute;
  inset: 0px 0px 40px auto;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 16px;
  background: #171818;
  width: 30%;
  height: 100%;
}

.image-modal {
  position: absolute;
  inset: 0px 0px 40px auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 16px;
  width: 100%;
  height: 100%;
}
.popup-modal {
  position: absolute;
  inset: 0px 0px 40px auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 16px;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
}

.custom-success-toast {
  background-color: #4caf50; /* Green */
  color: #fff;
}

.priority-container {
  position: relative;
}

.priority-icon {
  position: absolute;
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(0deg) brightness(118%) contrast(119%);
}

.card {
  position: relative;
  z-index: 20rem;
}

.card .card__container {
  position: relative;
}

.card::before {
  position: absolute;
  top: 46px;
  right: -8px;
  content: "";
  background: #861c0c;
  height: 28px;
  width: 28px;
  transform: rotate(45deg);
  z-index: 10rem;
}

.card::after {
  position: absolute;
  content: attr(data-label);
  top: 20px;
  right: -14px;
  padding: 0.5rem;
  width: 10rem;
  background: #bb1a1a;
  color: white;
  text-align: center;
  font-family: "Roboto", sans-serif;
  box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2);
  z-index: 40rem;
}

.card-gradient {
  background: linear-gradient(to right, #292929 10%, #393838 90%);
}
.side-card-gradient {
  background: linear-gradient(to right, #252525 100%, #4e81f4 0%);
}
.header-card-gradient {
  background: linear-gradient(to right, #4e81f4 19%, #ffffff 0%);
}

@media screen and (max-width: 768px) {
  .custom-modal {
    width: 100%;
    padding-bottom: 90px;
  }
  .popup-modal {
    top: 40%;
    width: 80%;
    margin: auto;
  }
}

@media screen and (min-width: 1290px) {
  .card-margin {
    margin-top: -50px;
    /* background: #E971B0; */
  }
}

/* navbar classes  */
/* 
.NAVBAR .active {
  position: relative;
}
.NAVBAR li {
  position: relative;
}
.NAVBAR li:hover::before {
  content: "";
  position: absolute;
  left: -30px;
  top: 0;
  height: 20px;
  width: 2px;
  background: #fff;
}
.NAVBAR .active::before {
  content: "";
  position: absolute;
  left: -30px;
  top: 0;
  height: 20px;
  width: 2px;
  background: #fff;
}
@media screen and (max-width: 768px) {
  .NAVBAR li:hover::before,
  .NAVBAR .active::before {
    left: -15px;
  }
} */

/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mydate {
  color-scheme: dark;
}

.bg-container-class .bg-main-class {
  background: linear-gradient(to right, #ad4601 0%, #dc2100 100%);
  opacity: 0.2;
}

.bg-container-class:hover .bg-main-class {
  background: linear-gradient(to right, #ad4601 0%, #dc2100 100%);
  opacity: 1;
}

.RichTextEditor__root___2QXK- {
  background: #202020 !important;
  border: 1px solid #ddd;
  border-radius: 3px;
  font-family: Georgia, serif;
  font-size: 14px;
  color: white !important;
}

.flag-dropdown .selected-flag {
  background-color: #171818 !important;
}

.custom-confirm-toast button:not(.custom-confirm-toast > button) {
  font-weight: medium;
  font-size: 16px;
  color: #777 !important;
  padding: 0.65rem 1.6rem;
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 15px;
}

.custom-confirm-toast button:first-child:not(.custom-confirm-toast > button) {
  font-weight: medium;
  font-size: 16px;
  color: white !important;
  padding: 0.65rem 1.6rem;
  background: #ff7e00 !important;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 15px;
}

.drop-shadow-standard {
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.8));
}

.mydateV2 .react-date-picker__wrapper {
  border: none;
}

.mydateV2 select {
  @apply bg-backgroundV2;
}

.mydateV2 .react-calendar__tile--now,
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus,
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  color: black;
}

.react-calendar__tile--active{
  color: white !important;
}

.mydateV2 svg {
  stroke: white;
}
